<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px" top="80px" height="600">
        <x-detail label-width="140px" :entity="entity">
            <x-divider title="基本信息" />
            <x-detail-item prop="jbry" label="交班人员" class="w50"/>
            <x-detail-item prop="jbmd" label="交班门店" class="w50"/>
            <x-detail-item prop="jbkssj" label="交班开始时间" class="w50"/>
            <x-detail-item prop="jbjssj" label="交班结束时间" class="w50"/>
            <x-detail-item prop="qxcsje" label="钱箱初始金额" class="line"/>
            <x-detail-item prop="qxjbje" label="钱箱交班金额" class="line"/>
            <x-divider title="消费" />
            <x-detail-item prop="xj" label="现金" class="w50"/>
            <x-detail-item prop="zfb" label="支付宝" class="w50"/>
            <x-detail-item prop="wx" label="微信" class="w50"/>
            <x-detail-item prop="hy" label="会员" class="w50"/>
            <x-divider title="充值" />
            <x-detail-item prop="xjcz" label="现金充值" class="w50"/>
            <x-detail-item prop="zfbcz" label="支付宝充值" class="w50"/>
            <x-detail-item prop="wxcz" label="微信充值" class="w50"/>
            <x-detail-item prop="hyczzs" label="会员充值赠送" class="w50"/>
            <x-detail-item prop="at" label="其他" class="line"/>
        </x-detail>
        <x-divider title="钱箱修改记录"></x-divider>
        <el-table :data="entity.jiaoBanQxxgjls" border class="x-detail-table">
            <el-table-column width="60" label="序号" type="index" />
            <el-table-column width="150" label="修改方式" >
                <x-dict-show slot-scope="{row}" :code="row.xgfs" dictType="T_JBJE_LX" />
            </el-table-column>
            <el-table-column width="150" prop="je" label="金额" />
            <el-table-column width="220" prop="xgsj" label="修改时间" />
            <el-table-column show-overflow-tooltip prop="bz" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/szgl/JiaoBan";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                jbry: "", // 交班人员
                jbryid: "", // 交班人员id
                jbmd: "", // 交班门店
                jbmdid: "", // 交班门店id
                jbkssj: "", // 交班开始时间
                jbjssj: "", // 交班结束时间
                qxcsje: "", // 钱箱初始金额
                qxjbje: "", // 钱箱交班金额
                xj: "", // 现金
                zfb: "", // 支付宝
                wx: "", // 微信
                hy: "", // 会员
                xjcz: "", // 现金充值
                zfbcz: "", // 支付宝充值
                wxcz: "", // 微信充值
                hyczzs: "", // 会员充值赠送
                at: "", // 其他
                jiaoBanQxxgjls: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>
