import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/jiao-ban/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/jiao-ban/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/jiao-ban/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/jiao-ban/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/jiao-ban/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/jiao-ban/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/jiao-ban/delete-batch',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'jiao-ban-list',
    component: () => import('@/view/szgl/cgsz/JiaoBanList'),
    name: 'JiaoBanList',
    meta: {title: '交班', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"交班基础列表","methodUrl":"/jiao-ban/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"交班添加保存","methodUrl":"/jiao-ban/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"交班获取编辑数据","methodUrl":"/jiao-ban/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"交班获取详情数据","methodUrl":"/jiao-ban/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"交班编辑保存","methodUrl":"/jiao-ban/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"交班删除","methodUrl":"/jiao-ban/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"交班批量删除","methodUrl":"/jiao-ban/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
