<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="交班人员" >
                <el-input v-model="search.jbry" size="small" />
            </x-search-item>
            <x-search-item label="交班开始时间" text-width="220px">
                <el-date-picker v-model="search.jbkssj" size="small" type="datetime" />
            </x-search-item>
            <x-search-item label="交班结束时间" text-width="220px">
                <el-date-picker v-model="search.jbjssj" size="small" type="datetime" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="220" show-overflow-tooltip prop="jbry" label="交班人员" />
            <el-table-column width="240" show-overflow-tooltip prop="jbmd" label="交班门店" />
            <el-table-column
                    label="时间"
                    min-width="220">
                <template slot-scope="scope">
                    <p>{{ '上班：'+  scope.row.jbkssj }}</p>
                    <p>{{ '交班：'+  scope.row.jbjssj }}</p>
                    <p>{{'间隔：'}}{{duration(scope.row.jbkssj,scope.row.jbjssj)}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    label="钱箱金额（元）"
                    min-width="200">
                <template slot-scope="scope">
                    <p>{{ '初始金额：'+  scope.row.qxcsje }}</p>
                    <p>{{ '交班金额：'+  scope.row.qxjbje }}</p>
                </template>
            </el-table-column>
            <el-table-column  prop="xj" label="现金" />
            <el-table-column  prop="zfb" label="支付宝" />
            <el-table-column  prop="wx" label="微信" />
            <el-table-column prop="hy" label="会员" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/szgl/JiaoBan";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/szgl/cgsz/JiaoBanDetail";

    export default {
        name: "JiaoBanList",
        mixins: [XTableBase],
        components: { Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    jbry: '',
                    jbmd: '',
                    jbkssj: '',
                    jbjssj: '',
                }
            }
        },
        methods:{
            duration: function (startTime,endTime) {
                if (endTime === ""){
                    return `??小时??分钟`;
                }
                const start = new Date(startTime);
                const end = new Date(endTime);
                const diff = (end.getTime() - start.getTime()) / 1000; // 时间差的秒数
                const hours = Math.floor(diff / 3600); // 小时数
                const minutes = Math.floor((diff - hours * 3600) / 60); // 分钟数
                return `${hours}小时${minutes}分钟`;
            }
        }
    }
</script>

<style scoped>
</style>
